import React, { useEffect } from 'react'
import contactMoji from '../assets/contactMoji.svg'
import { BsInstagram, BsGithub } from 'react-icons/bs'
import { FaFigma } from 'react-icons/fa'
import { AiOutlineLinkedin } from 'react-icons/ai'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Contact = () => {

    const [ref, inView] = useInView({ triggerOnce: true, });
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0, transition: { duration: 0.7, ease: 'easeInOut' }
            })
        }
        if (!inView) {
            animation.start({
                y: 300
            })
        }
    }, [inView]);

    return (
        <div name="contact" className='relative w-full h-screen px-4 py-20 bg-white lg:px-20 md:py-40 lg:py-20'>
            <div className='w-[70%] h-[100vh] rounded-br-full bg-[#CCECE7] absolute top-0 blur-2xl mix-blend-multiply left-0'></div>
            <div className='w-[70%] h-[100vh] rounded-tl-full bg-[#CAD2F9] absolute bottom-0 mix-blend-multiply blur-2xl opacity-75 right-0'></div>

            <motion.div ref={ref} animate={animation} className='w-full h-full p-4 md:p-10 lg:p-14 bg-[#121316] relative'>
                <div className='grid w-full h-full md:grid-cols-2 font-moskSemi'>
                    <div className="grid place-items-center">
                        <div className='pt-2 pl-2 text-gray-100 h-fit'>
                            <h1 className='text-3xl sm:text-4xl xl:text-5xl text-[#6DFACD] pb-4'>I'm always up for a chat</h1>
                            <p className='text-xl underline lg:text-2xl '>Pop me an e-mail</p>
                            <p className='flex pt-3 text-xl lg:text-2xl'>at <a href="mailto:shashant.dudam@gmail.com? subject=subject text" className='pl-2 hover:underline decoration-[#6dfacd]'> shashant.dudam@gmail.com</a> </p>
                            <p className='pt-3 text-xl lg:text-2xl '>or you can ping me on my social’s.</p>
                            <div className='flex gap-x-4 text-[#6DFACD] mt-4 md:mt-8'>
                                <a href='https://www.linkedin.com/in/shashant-dudam-9332a7214/' rel="noreferrer" target='_blank'><AiOutlineLinkedin size={36} className=' hover:opacity-50' /></a>
                                <a href='https://github.com/Sad7920' rel="noreferrer" target='_blank'><BsGithub size={36} className=' hover:opacity-50' /></a>
                                <a href='https://www.instagram.com/shashantttt/' rel="noreferrer" target='_blank'><BsInstagram size={36} className=' hover:opacity-50' /></a>
                                <a href='https://www.figma.com/files/user/1048931599239279279?fuid=1048931599239279279' rel="noreferrer" target='_blank'><FaFigma size={36} className=' hover:opacity-50' /></a>
                            </div>
                        </div>
                    </div>
                    <div className='grid place-items-center'>
                        <img src={contactMoji} alt='contact-moji' className=' w-96' />
                    </div>
                </div>
                {/* <h1 className='text-[#6dfacd] text-5xl md:text-6xl font-moskUltra text-shadow-md absolute -bottom-5'>Hey There!</h1> */}
            </motion.div>
        </div>
    )
}

export default Contact