import React from 'react'

const SkillCard = (props) => {
  return (
    <div className='p-8 text-center duration-300 bg-[#1d1e22] shadow hover:scale-105 sm:mt-0 rounded-2xl'>
              <div>
                {props.logo}
              </div>
              <h1 className='py-2 text-4xl font-moskSemi text-[#6dfacd]'>{props.head1}</h1>
              <p className='py-1 text-xl font-Roobert opacity-80'>{props.para1}</p>
              <h1 className='pt-6 pb-2 text-2xl font-moskSemi text-[#6dfacd]'>{props.head2}</h1>
              <p className='py-1 text-xl font-Roobert opacity-80'>{props.para2}</p>
              <h1 className='pt-6 pb-2 text-2xl font-moskSemi text-[#6dfacd]'>{props.head3}</h1>
              <ul>
                <li className='text-xl font-Roobert opacity-80'>{props.li1}</li>
                <li className='text-xl font-Roobert opacity-80'>{props.li2}</li>
                <li className='text-xl font-Roobert opacity-80'>{props.li3}</li>
                <li className='text-xl font-Roobert opacity-80'>{props.li4}</li>
                <li className='text-xl font-Roobert opacity-80'>{props.li5}</li>
              </ul>
            </div>
  )
}

export default SkillCard