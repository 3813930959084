import React, { Fragment, useState } from 'react'

import Logo from '../assets/logo.svg'

import { FiDownload } from 'react-icons/fi'
import { RiMenu4Line } from 'react-icons/ri'
import { BsArrowDownRight } from 'react-icons/bs'
import { VscChromeClose } from 'react-icons/vsc'

import { Menu, Transition } from '@headlessui/react'
import { Link } from 'react-scroll'
import Resume from '../assets/Resume.pdf'

const Navbar = () => {

    const [nav, setNav] = useState(false)

    const handleNavClick = () => {
        setNav(!nav);
    };

    return (
        <div className='fixed z-50 w-full h-20 px-4 text-gray-300'>
            <div className='w-full bg-[rgba(31,32,35,.8)] bg-opacity-60 backdrop-blur-[10px] h-[60px] max-w-4xl mx-auto p-3 mt-4 flex items-center rounded-full justify-between '>
                <img src={Logo} alt='Logo' className='h-10' />
                <div className='justify-between hidden w-full pl-4 md:flex'>
                    <div className='flex items-center text-lg'>
                        <div className=' font-Roboto hover:text-gray-50'>
                            <Link className='px-5' to="hello" smooth={true} offset={0} duration={1000}>Home</Link>
                        </div>
                        <div className='font-Roboto hover:text-gray-50'>
                            <Link className='px-5' to="about" smooth={true} offset={70} duration={1000}>About</Link>
                        </div>
                        <div className='font-Roboto hover:text-gray-50'>
                            <Link className='px-5' to="skillset" smooth={true} offset={-100} duration={1000}>Skillset</Link>
                        </div>
                        <div className='font-Roboto hover:text-gray-50' href='#'>
                            <Link className='px-5' to="projects" smooth={true} offset={-100} duration={1000}>Projects</Link>
                        </div>
                        <div className='font-Roboto hover:text-gray-50'>
                            <Link className='px-5' to="contact" smooth={true} offset={0} duration={1000}>Contact</Link>
                        </div>
                    </div>
                    <div>
                        <a href={Resume} className='flex items-center border border-gray-600 hover:bg-gray-600 hover:text-gray-200 hover:bg-opacity-50 my-[1px] rounded-full px-4 bg-transparent' download={true}>
                            <h1 className='pr-2 text-lg font-Roboto'>Resume</h1>
                            <FiDownload className='h-10' />
                        </a>
                    </div>
                </div>
                <Menu as='div' className='relative inline-block text-left md:hidden'>
                    <Menu.Button onClick={() => { setNav(!nav) }} className='flex items-center p-1 text-gray-300 bg-transparent border border-gray-600 rounded-full cursor-none hover:text-gray-50 hover:bg-gray-600 hover:bg-opacity-20 bg-opacity-20'>
                        {!nav ? <RiMenu4Line className='h-7 w-7' /> : <VscChromeClose className='h-7 w-7' />}
                    </Menu.Button>
                    <Transition as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='transform opacity-0 scale-50'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='focus:outline-none absolute right-0 mt-4 w-56 pt-4 origin-top-right rounded-2xl bg-[#1f2023]/95 backdrop-blur-md backdrop-filter flex flex-col text-gray-400 shadow-lg'>
                            <Menu.Item onClick={handleNavClick}><div className='flex items-center justify-between px-10 hover:text-white'>
                                <div className='py-2 text-xl font-Roboto ' >
                                    <Link to="hello" smooth={true} offset={0} duration={1000}>Home</Link>
                                </div>
                            </div>
                            </Menu.Item>
                            <Menu.Item onClick={handleNavClick}><div className='flex items-center justify-between px-10 hover:text-white'>
                                <div className='py-2 text-xl font-Roboto ' >
                                    <Link to="about" smooth={true} offset={0} duration={1000}>About</Link>
                                </div>
                                <BsArrowDownRight className='h-[18px] ' />
                            </div>
                            </Menu.Item>
                            <Menu.Item onClick={handleNavClick}><div className='flex items-center justify-between px-10 hover:text-white'>
                                <div className='py-2 text-xl font-Roboto ' >
                                    <Link to="skillset" smooth={true} offset={-100} duration={1000}>Skillset</Link>
                                </div>
                                <BsArrowDownRight className='h-[18px] ' />
                            </div>
                            </Menu.Item>
                            <Menu.Item><div className='flex items-center justify-between px-10 hover:text-white'>
                                <div onClick={handleNavClick} className='py-2 text-xl font-Roboto ' >
                                    <Link to="projects" smooth={true} offset={-100} duration={1000}>Projects</Link>
                                </div>
                                <BsArrowDownRight className='h-[18px] ' />
                            </div>
                            </Menu.Item>
                            <Menu.Item onClick={handleNavClick}><div className='flex items-center justify-between px-10 hover:text-white'>
                                <div className='py-2 text-xl font-Roboto ' >
                                    <Link to="contact" smooth={true} offset={0} duration={1000}>Contact</Link>
                                </div>
                                <BsArrowDownRight className='h-[18px] ' />
                            </div>
                            </Menu.Item>
                            <Menu.Item onClick={handleNavClick}>
                                <div className='px-6 py-2'>
                                    <a href={Resume} className='flex items-center border border-gray-600 hover:bg-gray-600 hover:text-white hover:bg-opacity-50 my-[1px] rounded-full px-11 bg-transparent' download={true}>
                                        <h1 className='pr-2 text-lg font-Roboto'>Resume</h1>
                                        <FiDownload className='h-10' />
                                    </a>
                                </div>
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    )
}

export default Navbar