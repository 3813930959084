import React, { useEffect } from 'react'
import skillText from '../assets/skillText.svg'
import skillMoji from '../assets/skillmoji.svg'
import { BiCodeAlt } from 'react-icons/bi'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import SkillCard from './SkillCard'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


const Skillset = () => {

  const [ref, inView] = useInView({ triggerOnce: true, });
  const [ref2, inView2] = useInView({ triggerOnce: true, rootMargin: '-300px 0px 0px 0px' });
  const animation = useAnimation();
  const animation2 = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1, transition: { duration: 2 }
      })
    }
    if (!inView) {
      animation.start({
        opacity: 0
      })
    }
  }, [inView]);

  useEffect(() => {
    if (inView2) {
      animation2.start({
        opacity: 1, transition: { duration: 2, delay: 0.5 }
      })
    }
    if (!inView2) {
      animation2.start({
        opacity: 0
      })
    }
  }, [inView2]);

  return (
    <div name="skillset" className='w-full bg-[#121316] px-4 text-gray-200 pb-40'>
      <div className='w-full max-w-6xl mx-auto'>
        <div className='relative w-full'>
          <motion.div ref={ref} animate={animation}>
            <img src={skillText} alt='skill-text' className='mx-auto' />
            <div className='absolute w-full top-4 sm:top-8 md:top-12 lg:top-20'>
              <img src={skillMoji} alt='skill-moji' className='mx-auto w-60 sm:w-72 md:w-80' />
            </div>
          </motion.div>
          <motion.div ref={ref2} animate={animation2} className='grid w-full gap-8 lg:gap-16 xl:gap-32 pt-52 md:grid-cols-2'>
            <SkillCard
              logo={<HiOutlinePencilAlt size={54} className='w-full text-4xl' />}
              head1='Designer'
              para1='I value simple content structure, clean design patterns, and thoughtful interactions.'
              head2='Things i enjoy designing:'
              para2='UI, UX, Web Apps, Mobile Apps, Logos'
              head3='Design Tools'
              li1='Figma'
              li2='Adobe Xd'
              li3='Vectornator'
              li4='Pen and Paper'
              li5='Webflow'
            />
            <SkillCard
              logo={<BiCodeAlt size={54} className='w-full text-4xl' />}
              head1='Web Dev'
              para1='I like to code things from scratch and enjoy bringing ideas to life in the browser.'
              head2='Languages I speak:'
              para2='JavaScript, Python, NodeJS, MongoDB'
              head3='Dev Tools'
              li1='VS Code'
              li3='Postman'
              li4='Github'
              li5='Terminal'
              li2='Notion'
            />
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Skillset