import React from 'react'

import { FiArrowUpRight } from 'react-icons/fi'

const ProjectCard = ({ skills, name, desc, link, img }) => {
    return (
        <div className=''>
            <a href={link} rel="noreferrer" target='_blank'>
                <div className='p-8 text-center relative pb-20 duration-300 bg-[#1d1e22] h-full shadow hover:scale-105 sm:mt-0 rounded-2xl flex flex-col'>
                    <div className='mb-8 shadow-lg shadow-black'>
                        <img src={img} alt="test" className='rounded-md' />
                    </div>
                    <div className='flex-grow text-left'>
                        <div className='flex gap-2'>
                            {skills.map((skill, idx) => (
                                <span key={idx} className='px-2 py-1 bg-[#4d4e59] font-medium text-[10px] rounded-full'>{skill}</span>
                            ))}
                        </div>
                        <h1 className='py-2 text-2xl font-moskSemi text-[#6dfacd]'>{name}</h1>
                        <p className='font-light opacity-80'>{desc}</p>
                        <div className='absolute bottom-8'>
                            <h2 className='flex items-center gap-4 mt-5 text-white '>
                                Open Project<FiArrowUpRight className='w-6 h-6 ' />
                            </h2>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default ProjectCard